D3RSite.preventScroll = {
    active: false,
    on: function() {
        var htmlEl = document.querySelector('html');
        var body = document.body;


        // Determine the `scrollTop` to use. Some browsers require checking the
        // `body`, others use `html`.
        var bodyScrollTop = body.scrollTop;
        var htmlScrollTop = htmlEl.scrollTop;
        var scrollTop = bodyScrollTop ? bodyScrollTop : htmlScrollTop;

        // Store the current value of the htmlEl's styles – we're about to override
        // them.
        this.currentPosition = htmlEl.style.position;
        this.currentOverflow = htmlEl.style.overflowY;
        this.currentWidth = htmlEl.style.width;

        // Add a class to prevent smooth scrolling from being used on the html and body
        htmlEl.classList.add('no-transition-scroll');

        // Fixing the position of the `htmlEl` prevents the page from scrolling
        // at all.
        htmlEl.style.position = 'fixed';
        // Setting `overflowY` to `scroll` ensures that any scrollbars that are
        // around stick around. Otherwise, there would be a "jump" when the page
        // becomes unscrollable as the bar would vanish.
        htmlEl.style.overflowY = 'scroll';
        // This makes sure that the page doesn't collapse (usually your CSS will
        // prevent this, but it's best to be safe)
        htmlEl.style.width = '100%';
        // Scoot down the `htmlEl` to be in the same place that the user had
        // scrolled to.
        htmlEl.style.top = (0 - scrollTop) + 'px';

        // Set active state
        this.active = true;
    },

    off: function() {
        var htmlEl = document.querySelector('html');
        var body = document.body;

        // Reset `htmlEl` to the original styles.
        htmlEl.style.position = this.currentPosition;
        htmlEl.style.overflowY = this.currentOverflow;
        htmlEl.style.width = this.currentWidth;

        // Retrieve our original scrollTop from the htmlEl's top
        var scrollTop = -parseInt(htmlEl.style.top);
        // Return us to the original scroll position. Once again, we set this on
        // both the `body` and the `htmlEl` to be safe.
        htmlEl.scrollTop = scrollTop;
        body.scrollTop = scrollTop;

        // Remove a class to prevent smooth scrolling from being used on the html and body
        htmlEl.classList.remove('no-transition-scroll');

        // Unset active state
        this.active = false;
    }
}
