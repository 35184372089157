D3RSite.preventScroll = {
    active: false,
    on: function() {
        var htmlEl = document.querySelector('html');
        var body = document.body;


        // Determine the `scrollTop` to use. Some browsers require checking the
        // `body`, others use `html`.
        var bodyScrollTop = body.scrollTop;
        var htmlScrollTop = htmlEl.scrollTop;
        var scrollTop = bodyScrollTop ? bodyScrollTop : htmlScrollTop;

        // Store the current value of the htmlEl's styles – we're about to override
        // them.
        this.currentPosition = htmlEl.style.position;
        this.currentOverflow = htmlEl.style.overflowY;
        this.currentWidth = htmlEl.style.width;

        // Add a class to prevent smooth scrolling from being used on the html and body
        htmlEl.classList.add('no-transition-scroll');

        // Fixing the position of the `htmlEl` prevents the page from scrolling
        // at all.
        htmlEl.style.position = 'fixed';
        // Setting `overflowY` to `scroll` ensures that any scrollbars that are
        // around stick around. Otherwise, there would be a "jump" when the page
        // becomes unscrollable as the bar would vanish.
        htmlEl.style.overflowY = 'scroll';
        // This makes sure that the page doesn't collapse (usually your CSS will
        // prevent this, but it's best to be safe)
        htmlEl.style.width = '100%';
        // Scoot down the `htmlEl` to be in the same place that the user had
        // scrolled to.
        htmlEl.style.top = (0 - scrollTop) + 'px';

        // Set active state
        this.active = true;
    },

    off: function() {
        var htmlEl = document.querySelector('html');
        var body = document.body;

        // Reset `htmlEl` to the original styles.
        htmlEl.style.position = this.currentPosition;
        htmlEl.style.overflowY = this.currentOverflow;
        htmlEl.style.width = this.currentWidth;

        // Retrieve our original scrollTop from the htmlEl's top
        var scrollTop = -parseInt(htmlEl.style.top);
        // Return us to the original scroll position. Once again, we set this on
        // both the `body` and the `htmlEl` to be safe.
        htmlEl.scrollTop = scrollTop;
        body.scrollTop = scrollTop;

        // Remove a class to prevent smooth scrolling from being used on the html and body
        htmlEl.classList.remove('no-transition-scroll');

        // Unset active state
        this.active = false;
    }
}


;(app => {
    function Header() {
        this.el = document.querySelector('.js-header');
        this.toggleButton = document.querySelector('.js-header__toggle-button');
        this.sentinel = document.querySelector('.js-sentinel');

        const viewport = app.viewport;

        // Initialise the header
        this.init = () => {
            if (this.el) {
                this.initObserver();
                this.watchToggleButton();
                this.watchResize();
            }
        }

        if (window.IntersectionObserver) {
            // Set up intersectional observer
            this.observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        this.el.setAttribute('data-header', 'static');
                    } else {
                        this.el.setAttribute('data-header', 'scroll');
                    }
                })
            });

            // Initialise Observer
            this.initObserver = () => this.observer.observe(this.sentinel);
        } else {
            this.initObserver = () => {
            };
        }

        // Watch the toggle button click events
        this.watchToggleButton = () => {
            this.toggleButton.addEventListener('click', this.toggle.bind(this));
        }

        // Watch the window for resize events
        this.watchResize = () => {
            viewport.matchMedia('nav').addListener((e) => {
                if (!e.matches) {
                    this.el.classList.remove('header--active');
                    this.el.classList.remove('header--activating');

                    if (!this.el.classList.contains('header--static')) {
                        this.el.setAttribute('data-header', 'static');
                    }
                }
            });
        }

        // Toggle the state of the header
        this.toggle = () => {
            if (viewport.matches('nav')) {
                if (this.el.classList.contains('header--active')) {
                    this.deactivate();
                    this.toggleButton.setAttribute('aria-expanded', false);
                } else {
                    this.activate();
                    this.toggleButton.setAttribute('aria-expanded', true);
                }
            }
        }

        // Activate the header
        this.activate = () => {
            if (!app.preventScroll.active) {
                app.preventScroll.on();
            }

            this.el.classList.add('header--activating');

            setTimeout(() => {
                this.el.classList.add('header--active');
            }, 1);
        }

        // Deactivate the header
        this.deactivate = () => {
            if (app.preventScroll.active) {
                app.preventScroll.off();
            }
            this.el.classList.remove('header--active');

            this.el.addEventListener('transitionend', () => {
                this.el.classList.remove('header--activating');
            }, {once: true});

            // Fallback in case transition hasn't fired
            setTimeout(() => {
                this.el.classList.remove('header--activating');
            }, 500);
        }
    }

    app.header = new Header();
    app.header.init();
})(window.D3RSite = window.D3RSite || {});
